/**************************************
    Default Styles
***************************************/

@import 'bootstrap/dist/css/bootstrap.min.css';
// @import url('../css/bootstrap/bootstrap-utilities.css');
// @import url('../css/bootstrap/bootstrap-grid.css');
@import url('../css/slick.css');
@import url('../css/slicktheme.css');
@import url('../css/animation.css');


@import 'default/variables';
@import 'default/mixins';
@import 'default/reset';
@import 'default/typography';
@import 'default/extend';
@import 'default/animations';
@import 'default/shortcode';
@import 'default/spacing';
@import 'default/common';
@import 'default/forms';


/**************************************
    Theme Styles
***************************************/

@import 'color/themebg';

/**************************************
    Header Styles
***************************************/
@import 'header/header';
@import 'header/nav';
@import 'header/mobilemenu';
@import 'header/headertop';


/**************************************
    Elements Styles
***************************************/
@import 'elements/sctiontitle';
@import 'elements/button';
@import 'elements/breadcrumb';
@import 'elements/accordion';
@import 'elements/service';
@import 'elements/card';
@import 'elements/progressbar';
@import 'elements/counter';
@import 'elements/portfolio';
@import 'elements/social';
@import 'elements/team';
@import 'elements/timeline';
@import 'elements/testimonial';
@import 'elements/slickslide';
@import 'elements/tab';
@import 'elements/pricing';
@import 'elements/split';
@import 'elements/callto-action';
@import 'elements/video';
@import 'elements/contact';
@import 'elements/brand';
@import 'elements/newsletter';
@import 'elements/advance-tab';
@import 'elements/advancepricing';
@import 'elements/about';
@import 'elements/swicher';
@import 'elements/404';



/**************************************
    Blog Styles
***************************************/

@import 'blog/blog';
@import 'blog/sidebar';
@import 'blog/blog-details';



/**************************************
    Footer Styles
***************************************/
@import 'template/banner';
@import 'template/portfoliodetails';
@import 'template/splash';



/**************************************
    Footer Styles
***************************************/

@import 'footer/footer';
@import 'footer/copyright';
@import 'footer/darkmode';

/* for desktop */
.whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background: linear-gradient(
        95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.whatsapp-icon {
    margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
    .whatsapp-icon {
        margin-top: 10px;
    }

    .whatsapp_float {
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 10px;
        font-size: 22px;
    }
}

